import { FC } from 'react'

export const HomeHeroHeader: FC = () => {
  return (
    <header className="h-full w-full text-white opacity-80 pt-24 md:pt-[10vh]">
      <div className="flex flex-col items-center justify-center px-4">
        <h1 className="text-3xl md:text-5xl font-logo font-light mb-4 select-none">
          大喵激光雕刻工作室
        </h1>
        <p className="text-sm md:text-base px-4 text-center">
          <span className="whitespace-nowrap">树叶定制，</span>
          <span className="whitespace-nowrap">激光打罐，</span>
          <span className="whitespace-nowrap">各种材质均可定制</span>
        </p>
      </div>
    </header>
  )
}
